import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useClassroom } from './useClassroom';
import logoImage from '../assets/logo/blue.svg';
import messages from '../assets/messages_he.json';
import MenuImg from '../assets/icons/blueMenu.svg';
import MainLayout from '../common/layouts/MainLayout';
import MainList from '../common/layouts/main_list/MainList';
import MainListItem from '../common/layouts/main_list/MainListItem';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { IClassMetadata } from 'src/wrapper';
import { classes_metadata } from 'src/metadata';
import { playSound } from 'src/common/common';
import subscribedLockSound from 'src/assets/sounds/subscribed_locked.mp3';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import '../common/layouts/main_list/MainList.css';
import './Classroom.css';

function Classroom(): JSX.Element {
    const { state } = useContext(AppContext);
    const { selectClass, render_drawings, get_class_progress, get_classroom_list } = useClassroom();
    const { getMQid } = useMediaQueryWrap();
    const rid = getMQid();

    const [durInstructions, setDurInstructions] = React.useState(false);

    const onInstructionsClick = () => {
        if (durInstructions) return;
        setDurInstructions(true);
        playSound(subscribedLockSound, 1, () => setDurInstructions(false));
    };

    return (
        <MainLayout can_url="/classroom" id={`cr_${rid}`} logo={logoImage} appMenuLogo={MenuImg} in_a_course>
            <MainList
                text={
                    <>
                        <h1 className={'mlMainHeader'}>{messages['classroom.header']}</h1>
                        <h2 className="mlSubHeader">
                            {messages['classroom.subHeader1']} <br /> {messages['classroom.subHeader2']} <br />
                        </h2>
                        <p className="mlSubHeaderSmall">
                            {messages['classroom.subHeader3']} <br /> {messages['classroom.subHeader4']} <br />
                            {messages['classroom.subHeader5']}
                        </p>
                    </>
                }
                mainList={get_classroom_list().map((item, index) => {
                    const class_meta: IClassMetadata =
                        state.course_key != ''
                            ? classes_metadata[state.course_key][index]
                            : classes_metadata['heb1'][index];
                    return (
                        <MainListItem
                            key={index}
                            main_img={class_meta.img}
                            title={class_meta.title}
                            materials={class_meta.materials}
                            time_len={class_meta.time_len}
                            locked={item.locked}
                            progress={get_class_progress(item)}
                            onClick={() => selectClass(index)}
                        />
                    );
                })}
            />
            {render_drawings()}
            <img src={megaphoneSvg} onClick={onInstructionsClick} className="crMegaphone" alt="Megaphone" />
        </MainLayout>
    );
}

export default Classroom;
