import React from 'react';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import { game_image_path, useCashGameConfig } from './CashGameConfig';
import CashGameCoin from './CashGameCoin';
import { playSound } from 'src/common/common';
import sccSound from 'src/assets/sounds/success1.mp3';
import csahSound from 'src/assets/sounds/cash.mp3';
import insSound from 'src/assets/sounds/game_cash.mp3';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import CircleButton from 'src/common/buttons/CircleButton';
import { get_txt_btn_font_size } from 'src/common/common';
import './CashGame.css';

const image_rez = 1.77;
const image_registry_ratio = 0.38;
const image_object_ratio = 0.42;
const coins_ratio = 0.2;

export interface ICashGame {
    width: number;
    height: number;
    onFinish: () => void;
    object: string;
}

function CashGame({ width, height, onFinish, object }: ICashGame): JSX.Element {
    const { should_turn_screen, getMQid, get_action_btn_zoom } = useMediaQueryWrap();
    const [current_amount, set_current_amount] = React.useState(0);
    const { games } = useCashGameConfig();
    const [started, setStarted] = React.useState(false);

    const rid = getMQid();

    React.useEffect(() => {
        set_current_amount(0);
    }, [object]);

    const on_coin_click = (amount: number) => {
        if (!started) return;

        if (current_amount === games[object].price) return;
        if (current_amount + amount < 0) return;
        set_current_amount(current_amount + amount);
        if (current_amount + amount === games[object].price) {
            playSound(sccSound, 0.2);
            onFinish();
        } else if (amount > 0) {
            playSound(csahSound, 0.2);
        }
    };

    const onInsturctionClick = () => {
        playSound(insSound);
        setStarted(true);
    };

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;
        const font_size = get_txt_btn_font_size(rid);

        return (
            <div className="cash_game_wrapper" style={{ width: width, height: height }}>
                <img
                    src={games[object].image}
                    width={image_object_ratio * height * image_rez}
                    height={image_object_ratio * height}
                    alt={object}
                ></img>
                <div className="cash_registry_wrapper">
                    <img
                        className="cash_game_cash_container"
                        src={`${game_image_path}/cash_register.webp`}
                        width={image_registry_ratio * height}
                        height={image_registry_ratio * height}
                        alt={object}
                    ></img>
                    <p className="cash_game_amount" style={{ fontSize: font_size }}>
                        ₪{current_amount}
                    </p>
                </div>
                <div className="cash_game_coins" style={{ height: height * coins_ratio }}>
                    <CashGameCoin on_click={on_coin_click} amount={10} font_size={font_size} />
                    <CashGameCoin on_click={on_coin_click} amount={5} font_size={font_size} />
                    <CashGameCoin on_click={on_coin_click} amount={2} font_size={font_size} />
                    <CashGameCoin on_click={on_coin_click} amount={1} font_size={font_size} />
                </div>

                {!started && (
                    <div
                        className="game_instruction"
                        style={{
                            width: height * image_registry_ratio,
                            height: height * image_registry_ratio,
                            top: 50,
                            right: 100,
                        }}
                    >
                        <CircleButton
                            svg={megaphoneSvg}
                            color={'white'}
                            onClick={onInsturctionClick}
                            zoom={get_action_btn_zoom() + 1.2}
                            ariaLabel="הוראות"
                            inf_anim={'normal'}
                        />
                    </div>
                )}
            </div>
        );
    };

    return render();
}

export default CashGame;
