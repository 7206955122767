import React from 'react';
import shipSvg from '../../assets/images/ship.svg';
import Letter from '../../common/buttons/Letter';
import { letterColorType, letterColors } from '../../common/buttons/colors';
import { playSound } from 'src/common/common';
import sccSound from '../../assets/sounds/success1.mp3';
import './Puzzle.css';

export interface IPuzzleItem {
    size: number;
    text: string;
    isDrag: boolean;
    x: number;
    y: number;
    tx: number;
    ty: number;
    finish_cb: Function;
    bounds: DOMRect;
}

function PuzzleItem(props: IPuzzleItem): JSX.Element {
    const [color, setColor] = React.useState('blue' as letterColorType);
    const [finish, setFinish] = React.useState(false);
    const [isMouse, setIsMouse] = React.useState(false);
    const itemRef: React.RefObject<HTMLDivElement> = React.createRef();

    function colide(x: number, y: number) {
        const xx = x - props.bounds.left;
        const yy = y - props.bounds.top;
        return (
            xx + props.size > props.tx &&
            xx < props.tx + props.size &&
            yy + props.size > props.ty &&
            yy < props.ty + props.size
        );
    }

    function inScreen(x: number, y: number) {
        return (
            x + props.size / 2 < props.bounds.width + props.bounds.left &&
            x - props.size / 2 > props.bounds.left &&
            y + props.size / 2 < props.bounds.height + props.bounds.top &&
            y - props.size / 2 > props.bounds.top
        );
    }

    function OnMove(x: number, y: number) {
        if (!itemRef.current) return;
        if (!inScreen(x, y)) return;

        itemRef.current.style.zIndex = '1';

        // touching from center of rect
        itemRef.current.style.left = x - props.bounds.left - props.size / 2 + 'px';
        itemRef.current.style.top = y - props.bounds.top - props.size / 2 + 'px';

        // change color when colide
        const col = colide(x - props.size / 2, y - props.size / 2);
        const color = col ? 'green' : 'orange';
        // const color = 'blue';
        setColor(color);
    }

    function onMoveEnd(x: number, y: number) {
        setColor('blue');

        if (!itemRef.current) return;
        itemRef.current.style.zIndex = '0';

        const col = colide(x - props.size / 2, y - props.size / 2);
        if (col) {
            // playSuccess({ forceSoundEnabled: true });
            playSound(sccSound, 0.2);
            itemRef.current.style.left = props.tx + 'px';
            itemRef.current.style.top = props.ty + 'px';
            props.finish_cb();
            setColor('green');
            setFinish(true);
        }
    }

    function onTouchMove(e: any) {
        if (!props.isDrag || finish) return;
        const touchLocation = e.targetTouches[0];
        OnMove(touchLocation.clientX, touchLocation.clientY);
    }

    function onTouchEnd(e: any) {
        if (!props.isDrag || finish) return;
        const touchLocation = e.changedTouches[0];
        onMoveEnd(touchLocation.clientX, touchLocation.clientY);
    }

    function onMouseMove(e: any) {
        if (!props.isDrag || finish) return;
        if (!isMouse) return;
        OnMove(e.pageX, e.pageY);
    }

    function onMouseUp(e: any) {
        if (!props.isDrag || finish) return;
        setIsMouse(false);
        onMoveEnd(e.pageX, e.pageY);
    }

    function onMousedown() {
        if (!props.isDrag || finish) return;
        setIsMouse(true);
    }

    return (
        <div
            className="puzzleItemWrap"
            style={{ top: props.y, left: props.x, width: props.size, height: props.size }}
            ref={itemRef}
            onMouseDown={() => onMousedown()}
            onMouseMove={(ev) => onMouseMove(ev)}
            onMouseUp={(ev) => onMouseUp(ev)}
            onTouchEnd={(ev) => onTouchEnd(ev)}
            onTouchMove={(ev) => onTouchMove(ev)}
        >
            <img src={shipSvg} width={props.size} height={props.size} alt="תמונת כיבשה בפאזל"></img>
            <div className="puzzleItemTextWrap">
                <Letter
                    text={props.text}
                    id={'letter'}
                    zoom={props.size / 85.3}
                    color={letterColors[color]}
                    onClick={props.isDrag && !finish ? () => null : undefined}
                />
            </div>
        </div>
    );
}

export default PuzzleItem;
