import React from 'react';
import { useMediaQueryWrap } from '../../common/useMediaQueryWrap';
import Turnscreen from '../../common/layouts/TurnScreen';
import MazeLetter from './MazeLetter';
import { ILetterId, puzzlleLvl } from 'src/wrapper';
import MazeCell from './MazeCell';
import MazePlayer from './MazePlayer';
import MazeNavBtns from './MazeNavBtns';
import sccSound from 'src/assets/sounds/success1.mp3';
import megaphoneSvg from 'src/assets/icons/megaphone.svg';
import { letterColorType } from 'src/common/buttons/colors';
import CircleButton from 'src/common/buttons/CircleButton';
import { useMazeConfig } from './MazeConfig';
import { playSound } from 'src/common/common';

export interface IMaze {
    width: number;
    height: number;
    onFinish: () => void;
    lvl: puzzlleLvl;
    type: ILetterId;
}

const columns = 15;
const rows = 7;
const cells = columns * rows;

function Maze({ width, height, onFinish, lvl, type }: IMaze): JSX.Element {
    const { should_turn_screen, is_horiz } = useMediaQueryWrap();
    const [started, setStarted] = React.useState(false);
    const { games } = useMazeConfig();
    const { maze_levels } = games[type];

    const col_step = width / columns;
    const row_step = height / rows;

    const render_size = Math.min(col_step, row_step);
    const min_step = Math.min(col_step, row_step);

    const flat_idx_to_pos = (idx: number) => {
        const x_idx = idx % columns;
        const y_idx = Math.floor(idx / columns);

        let left = width - min_step * (x_idx + 1);
        let top = height - min_step * (rows - y_idx);

        const spare_size = Math.max(col_step, row_step) - min_step;

        if (row_step > col_step) top -= (spare_size * (columns - 1)) / 4;
        else left -= (spare_size * (columns - 1)) / 2;

        return {
            left,
            top,
        };
    };

    const letters_render_init = maze_levels[lvl].content.map((content, idx) => {
        return {
            ...flat_idx_to_pos(maze_levels[lvl].indices[idx]),
            content: content,
            color: 'blue',
        };
    });

    const cell_pos = [...Array(cells).keys()].map((_, index) => {
        return flat_idx_to_pos(index);
    });

    const [player_pos, set_player_pos] = React.useState({ top: 0, left: 0 });
    const [player_idx, set_player_idx] = React.useState(0);
    const [curr_letter_idx, set_curr_letter_idx] = React.useState(maze_levels[lvl].mod2_even ? 1 : 0);
    const [lettes_render, set_lettes_render] = React.useState(letters_render_init);

    const on_nav_click = (step: number) => {
        if (!started) return;
        const new_idx = player_idx + step;
        // borders
        if (new_idx < 0 || new_idx >= cells) return;
        if (step === 1 && player_idx % columns === columns - 1) return;
        if (step === -1 && player_idx % columns === 0) return;
        // map blocks
        if (maze_levels[lvl].cell_blocks.includes(new_idx)) return;

        // rotate player image
        if (step === -1) document.getElementById('maze_player')?.classList.add('rotateRight');
        if (step === 1) document.getElementById('maze_player')?.classList.remove('rotateRight');

        //colide next letter
        if (new_idx === maze_levels[lvl].indices[curr_letter_idx]) {
            if (curr_letter_idx === maze_levels[lvl].indices.length - 1) onFinish();
            if (maze_levels[lvl].mod2_odd && curr_letter_idx === maze_levels[lvl].indices.length - 2) onFinish();

            playSound(sccSound, 0.2);

            const new_lettes_render = [...lettes_render];
            new_lettes_render[curr_letter_idx].color = 'green';
            set_lettes_render(new_lettes_render);
            if (maze_levels[lvl].mod2_odd || maze_levels[lvl].mod2_even) set_curr_letter_idx(curr_letter_idx + 2);
            else set_curr_letter_idx(curr_letter_idx + 1);
        }

        set_player_idx(new_idx);
        set_player_pos(flat_idx_to_pos(new_idx));
        document.getElementById('maze_player')?.classList.add('withAnimation');
    };

    const reset = () => {
        set_player_idx(0);
        set_player_pos(flat_idx_to_pos(0));
        set_curr_letter_idx(maze_levels[lvl].mod2_even ? 1 : 0);
        set_lettes_render(letters_render_init);
    };

    React.useEffect(() => {
        reset();
    }, [col_step, row_step, is_horiz]);

    React.useEffect(() => {
        const player = document.getElementById('maze_player');
        if (!player) return;
        player.addEventListener('animationend', () => {
            player.classList.remove('withAnimation');
        });
    }, []);

    const onKeyboard = (e: any) => {
        e.preventDefault();
        if (e.keyCode == 37) {
            on_nav_click(1);
        } else if (e.keyCode == 39) {
            on_nav_click(-1);
        } else if (e.keyCode == 40) {
            on_nav_click(columns);
        } else if (e.keyCode == 38) {
            on_nav_click(-columns);
        } else {
            return;
        }
    };

    const onInsturctionClick = () => {
        playSound(maze_levels[lvl].instructions);
        setStarted(true);
        document.getElementById('maze_wrap')?.focus();
    };

    const render = () => {
        if (should_turn_screen()) return <Turnscreen />;
        return (
            <div
                id={'maze_wrap'}
                tabIndex={0}
                className="maze_wrap"
                style={{ width: width, height: height }}
                onKeyDown={onKeyboard}
            >
                {cell_pos.map((cell, index) => (
                    <MazeCell
                        key={index}
                        size={render_size}
                        top={cell.top}
                        left={cell.left}
                        text={index.toString()}
                        isBlock={maze_levels[lvl].cell_blocks.includes(index)}
                        mazeType={type}
                    />
                ))}
                {lettes_render.map((pos, index) => (
                    <MazeLetter
                        key={index}
                        size={render_size}
                        top={pos.top}
                        left={pos.left}
                        color={pos.color as letterColorType}
                        text={lettes_render[index].content}
                        type={type}
                    />
                ))}
                <MazePlayer size={render_size} top={player_pos.top} left={player_pos.left} type={type} />
                <MazeNavBtns btn_size={render_size} columns={columns} onClick={on_nav_click} mazeType={type} />
                {!started && (
                    <div
                        className="game_instruction"
                        style={{
                            width: render_size * 2,
                            height: render_size * 2,
                            top: player_pos.top,
                            left: player_pos.left - render_size,
                        }}
                    >
                        <CircleButton
                            svg={megaphoneSvg}
                            color={'white'}
                            onClick={onInsturctionClick}
                            zoom={(render_size / 85.3) * 2}
                            ariaLabel="הוראות"
                            inf_anim={'normal'}
                        />
                    </div>
                )}
            </div>
        );
    };

    return render();
}

export default Maze;
