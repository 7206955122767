import React from 'react';
import errSound from '../../assets/sounds/error.mp3';
import sccSound from '../../assets/sounds/success1.mp3';
import { playSound } from 'src/common/common';
import Letter from '../../common/buttons/Letter';
import { letterColors } from '../../common/buttons/colors';
import './Answers.css';

interface IAnswers {
    answers: string[];
    correct: string;
    onCorrect: () => void;
    zoom: number;
    successSound?: boolean;
}

function Answers(props: IAnswers): JSX.Element {
    const onClick = (ans: string) => {
        if (ans == props.correct) {
            if (props.successSound) playSound(sccSound, 0.2);
            props.onCorrect();
        } else playSound(errSound, 0.5);
    };

    return (
        <div className="answers_wrap">
            {props.answers.map((ans, index) => (
                <Letter
                    id={'number'}
                    key={index}
                    text={ans}
                    onClick={() => onClick(ans)}
                    zoom={props.zoom}
                    color={letterColors['blue']}
                />
            ))}
        </div>
    );
}

export default Answers;
