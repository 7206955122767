import React, { useContext, useEffect } from 'react';
import NextSvg from '../assets/icons/next.svg';
import BackSvg from '../assets/icons/back.svg';
import { AppContext } from '../AppContext';
import CircleButton from '../common/buttons/CircleButton';
import { useMediaQueryWrap } from '../common/useMediaQueryWrap';
import { finishToPage, ICameraTask, IImage } from '../wrapper';
import { useNav } from '../common/useNav';
import Answers from '../features/answers/Answers';
import Mic from '../features/mic/Mic';
import Camera from '../features/camera/Camera';
import AbRuler from '../features/letters/AbRuler';
import Video from '../features/video/Video';
import { uploadClasses, uploadClassesAndImg } from '../common/api/server.api';
import errSound from '../assets/sounds/error.mp3';
import sccSound from '../assets/sounds/success1.mp3';
import { classes_metadata } from 'src/metadata';
import { useClassProgMicTask } from './useClassProgMicTask';
import { useClassPuzzle } from './useClassPuzzle';
import { useClassSizes } from './useClassSizes';
import { useAddress } from 'src/common/address/useAddess';
import { v4 as uuidv4 } from 'uuid';
import { useServer } from 'src/common/api/useServer';
import { useClassPaintMaze } from './useClassPaintMaze';
import { useClassMaze } from './useClassMaze';
import LinearProgress from '@mui/material/LinearProgress';
import StarProgress from 'src/features/starProgress/StarProgress';
import { playSound } from 'src/common/common';
import './Class.css';

export function useClass() {
    const { state, setState } = useContext(AppContext);
    const [vidOver, setVidOver] = React.useState(false);
    const [play, setPlay] = React.useState(true);
    const [classFinishedAnim, setClassFinishedAnim] = React.useState(false);
    const [finishTo, setFinishTo] = React.useState<finishToPage>('none');
    const { getMQid, get_action_btn_zoom } = useMediaQueryWrap();
    const { navigateToClassroom, navigateToFolder } = useNav();
    const { toggle_address_modal } = useAddress();
    const token = localStorage.getItem('ib_token');
    const { handle_no_token_err } = useServer();

    useEffect(() => {
        if (!token || state.course_key === '' || !state.subscribed || !(state.course_key in state.courses))
            navigateToClassroom();
    }, []);

    const get_curr_course = () => {
        if (state.course_key === '') return undefined;
        if (!(state.course_key in state.courses)) return undefined;

        return state.courses[state.course_key];
    };

    const get_curr_class = () => {
        if (state.course_key === '') return undefined;
        if (!(state.course_key in state.courses)) return undefined;

        const curr_course = state.courses[state.course_key];
        const curr_class = curr_course.classes[curr_course.curr_class_idx];
        return curr_class;
    };

    const get_task = () => {
        if (state.course_key === '') return undefined;
        if (!(state.course_key in state.courses)) return undefined;

        const curr_course = state.courses[state.course_key];
        const curr_class = curr_course.classes[curr_course.curr_class_idx];
        const class_tasks = classes_metadata[state.course_key][curr_course.curr_class_idx].tasks;
        const task = class_tasks[curr_class.taskIndex];
        return task;
    };

    const actionBtnZoom = get_action_btn_zoom();

    const { get_vid_width, letterZoom, contentHeight } = useClassSizes({ task: get_task() });

    // progress state-logic
    const [playProgVid, setPlayProgVid] = React.useState(true);

    const on_vid_over = () => {
        setVidOver(true);
        if (state.course_key === '') return;
        if (get_task()?.fillAddress) toggle_address_modal(state.course_key);
    };

    const {
        render_mic_prog,
        render_ab_ruler_prog,
        nextProgTask,
        progTaskStopIndex,
        setProgTaskIndex,
        setProgTaskStopIndex,
    } = useClassProgMicTask({
        task: get_task(),
        letterZoom,
        setPlayProgVid,
        on_task_over: on_vid_over,
    });

    const reset_prog_task = () => {
        setProgTaskIndex(0);
        setProgTaskStopIndex(0);
        setPlayProgVid(true);
    };

    const adv_task_aux = (adv: number) => {
        const curr_course = get_curr_course();
        if (!curr_course) return;
        const class_tasks = classes_metadata[state.course_key][curr_course.curr_class_idx].tasks;

        const curr_class_idx = curr_course.curr_class_idx;
        const classes = [...curr_course.classes];
        const curr_class = classes[curr_class_idx];

        if (adv === 1 && curr_class.taskIndex == class_tasks.length - 1) {
            // unlock next class
            curr_class.finished = true;
            curr_class.taskIndex = 0; // finish -> reset task idx so we can start again
            if (curr_class_idx < classes.length - 1) {
                const next_class = classes[curr_class_idx + 1];
                next_class.locked = false;
            }
        } else curr_class.taskIndex += adv;
        return classes;
    };

    const update_folder_aux = (img: string) => {
        const curr_course = get_curr_course();
        if (!curr_course) return;

        const folder = [...curr_course.img_folder];
        const title = classes_metadata[state.course_key][curr_course.curr_class_idx].title;
        const iimg: IImage = {
            img_src: img,
            title: title,
            sub_folder: curr_course.curr_class_idx.toString(),
            id: uuidv4(),
        };
        folder.push(iimg);
        return folder;
    };

    const updade_folder_and_next = (img: string) => {
        const folder = update_folder_aux(img);
        const classes = adv_task_aux(1);

        if (!folder || !classes) return;

        const courses = { ...state.courses };
        courses[state.course_key].img_folder = folder;
        courses[state.course_key].classes = classes;

        setState({ ...state, courses: courses, cameraOpen: false });
        uploadClassesAndImg(token!, classes, folder[folder.length - 1], state.course_key, handle_no_token_err);
    };

    const adv_task = (adv: number) => {
        setVidOver(false);
        const classes = adv_task_aux(adv);
        if (!classes) return;
        setPlay(true);

        const courses = { ...state.courses };
        courses[state.course_key].classes = classes;

        setState({ ...state, courses: courses });
        if (!classes) return;
        uploadClasses(token!, classes, state.course_key, handle_no_token_err);
    };

    const exit_class_and_navigate = () => {
        setClassFinishedAnim(false);
        setVidOver(true);
        if (finishTo === 'classroom') navigateToClassroom();
        if (finishTo === 'folder') navigateToFolder();
    };

    const next = () => {
        const curr_task = get_task();
        adv_task(1);
        if (curr_task?.finish_to_classroom) {
            playSound(sccSound, 0.2);
            setClassFinishedAnim(true);
            setFinishTo('classroom');
        } else if (curr_task?.finish_to_folder) {
            playSound(sccSound, 0.2);
            setClassFinishedAnim(true);
            setFinishTo('folder');
        }
    };

    const back = () => {
        const curr_course = get_curr_course();
        const curr_class = get_curr_class();
        if (!curr_class || !curr_course) return;
        if (curr_class.taskIndex == 0) return;

        const class_tasks = classes_metadata[state.course_key][curr_course.curr_class_idx].tasks;
        if (!class_tasks[curr_class.taskIndex - 1].ab_select_hold) setSelectedLetters([]);

        reset_prog_task();
        setVidOver(false);
        adv_task(-1);
    };

    const render_next_button = () => {
        if (classFinishedAnim) return;

        let is_hide = !vidOver;
        if (!get_task()?.pressNext) is_hide = true;
        if (get_task()?.pressNextDur) is_hide = false;
        if (get_curr_class()?.finished) is_hide = false;
        const rid = getMQid();

        return (
            <div id={'next_horiz'} className="navBtnTextWrap" style={{ visibility: is_hide ? 'hidden' : 'unset' }}>
                <CircleButton
                    svg={NextSvg}
                    color={'green'}
                    inf_anim={'explode'}
                    onClick={next}
                    zoom={actionBtnZoom}
                    ariaLabel="המשך"
                />
                <p id={rid} className="navText">
                    המשך
                </p>
            </div>
        );
    };

    const render_back_button = () => {
        if (classFinishedAnim) return;
        let is_hide = false;
        if (get_task()?.pressBack === false) is_hide = true;
        const rid = getMQid();

        return (
            <div id={'back_horiz'} className="navBtnTextWrap" style={{ visibility: is_hide ? 'hidden' : 'unset' }}>
                <CircleButton svg={BackSvg} color={'blue'} onClick={back} zoom={actionBtnZoom} ariaLabel="חזור" />
                <p id={rid} className="navText">
                    חזור
                </p>
            </div>
        );
    };

    const { render_puzzle, is_puzzle } = useClassPuzzle({ task: get_task(), contentHeight, onFinish: next });
    const { render_maze, is_maze } = useClassMaze({ task: get_task(), contentHeight, onFinish: next });
    const { render_paint_maze, is_paint_maze } = useClassPaintMaze({
        task: get_task(),
        contentHeight,
        onFinish: next,
    });

    const render_answers = () => {
        const task = get_task();
        if (!task) return null;
        if (!task.answers || !vidOver) return null;
        return (
            <Answers
                answers={task.answers.answers}
                correct={task.answers.correct}
                onCorrect={next}
                zoom={letterZoom}
                successSound={task.successSound}
            />
        );
    };

    const get_class_progress_colors = (idx: number, curr_class: number) => {
        if (idx > curr_class) return { text_color: '#5B8DB4', bg_color: '#D2E8F9' };
        else if (idx === curr_class) return { text_color: 'white', bg_color: '#6FCF4D' };
        else return { text_color: '#345487', bg_color: '#FEDD3A' };
    };

    const render_class_progress = () => {
        const curr_course = get_curr_course();
        if (!curr_course) return;
        const course_classes = Array.from({ length: curr_course.classes.length }, (_, i) => (i + 1).toString());
        return (
            <div className="class_progress_wrap">
                <div className="class_progress_stars">
                    {course_classes.map((class_num, idx) => {
                        const colors = get_class_progress_colors(idx, curr_course.curr_class_idx);
                        return <StarProgress key={class_num} text={class_num} zoom={1} {...colors} />;
                    })}
                </div>

                <LinearProgress
                    sx={{
                        backgroundColor: '#D2E8F9',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#6CBFF7',
                        },
                    }}
                    variant="determinate"
                    value={((curr_course.curr_class_idx + 1) / course_classes.length) * 100} // Fixed the value calculation
                />
            </div>
        );
    };

    const render_mic = () => {
        if (!vidOver) return null;
        const task = get_task();
        if (!task) return null;
        if (!task.micTask) return null;
        return <Mic task={task.micTask} btn_zoom={actionBtnZoom} mic_over_cb={next} successSound={task.successSound} />;
    };

    const on_camera_over = (img: string, cameraTask: ICameraTask) => {
        updade_folder_and_next(img);
        if (cameraTask.finish_to === 'folder' || cameraTask.finish_to === 'classroom') {
            playSound(sccSound, 0.2);
            setClassFinishedAnim(true);
            setFinishTo(cameraTask.finish_to);
        }
    };

    // letter selection logic
    const [selectedLetters, setSelectedLetters] = React.useState([] as string[]);

    const on_letter_selected_over = (letter: string, total: number) => {
        const letters = [...selectedLetters];
        if (letters.includes(letter)) return;
        letters.push(letter);
        setSelectedLetters(letters);
        if (letters.length === total) next();
    };

    const render_ab_ruler_during = () => {
        const task = get_task();
        if (!task) return null;

        if (task.ab_select_finish) return null;
        if (task.ab_ruler_match) return null;

        if (selectedLetters.length > 0 && !task.ab_select) {
            return <AbRuler letters={selectedLetters} zoom={letterZoom} id={task.ab_type} />;
        }
    };

    const render_ab_ruler_select = () => {
        const task = get_task();
        if (!task) return null;

        if (!vidOver || !task.ab_select) return null;
        return (
            <AbRuler
                onClick={(letter: string) => on_letter_selected_over(letter, task.ab_select!)}
                letters={selectedLetters}
                zoom={letterZoom}
                id={task.ab_type}
            />
        );
    };

    const eqSet = (xs: Set<string>, ys: Set<string>) => xs.size === ys.size && [...xs].every((x) => ys.has(x));

    const on_letter_matched_over = (letter: string, match: string[]) => {
        const letters = [...selectedLetters];
        if (letters.includes(letter)) return;
        if (!match.includes(letter)) {
            playSound(errSound, 0.5);
            return;
        }
        playSound(sccSound, 0.2);
        letters.push(letter);
        setSelectedLetters(letters);

        if (eqSet(new Set(letters), new Set(match))) {
            setSelectedLetters([]);
            next();
        }
    };

    const render_ab_ruler_match = () => {
        const task = get_task();
        if (!task) return null;

        if (!vidOver || !task.ab_ruler_match) return null;

        return (
            <AbRuler
                onClick={(letter: string) => on_letter_matched_over(letter, task.ab_ruler_match!)}
                letters={selectedLetters}
                zoom={letterZoom}
                id={task.ab_type}
            />
        );
    };

    // Single stop state-logic
    const [singleStopReached, setSingleStopReached] = React.useState(false);

    const render_camera = () => {
        const task = get_task();
        if (!task) return null;

        if (!task.cameraTask) return null;
        if (task.render_time && !singleStopReached) return null;
        if ((task.render_time && singleStopReached) || !task.render_time)
            return <Camera on_camera_over={(img) => on_camera_over(img, task.cameraTask!)} btn_zoom={actionBtnZoom} />;
        if (!vidOver) return null;
    };

    const render_video = () => {
        const task = get_task();
        if (!task) return null;

        const vid_frame = task.progressVideo ? (
            <Video
                play={playProgVid && !classFinishedAnim}
                setPlay={setPlayProgVid}
                url={task.video_url}
                controls={false}
                progressInterval={250}
                vid_over_cb={on_vid_over}
                mulTasks={{
                    stops: task.progressVideo.video_stops,
                    task_over_cb: () => nextProgTask(true),
                    curr_stop: progTaskStopIndex,
                }}
            />
        ) : (
            <Video
                play={play && !classFinishedAnim}
                controls={true}
                setPlay={setPlay}
                url={task.video_url}
                vid_over_cb={on_vid_over}
                singleStop={{
                    stop: task.render_time,
                    singleStopReached: singleStopReached,
                    setSingleStopReached: setSingleStopReached,
                }}
            />
        );
        return <div style={{ width: get_vid_width }}>{vid_frame}</div>;
    };

    return {
        render_next_button,
        render_back_button,
        render_answers,
        render_video,
        render_camera,
        render_mic,
        render_mic_prog,
        render_ab_ruler_prog,
        render_puzzle,
        is_puzzle,
        render_paint_maze,
        is_paint_maze,
        render_maze,
        is_maze,
        render_ab_ruler_select,
        render_ab_ruler_during,
        render_ab_ruler_match,
        classFinishedAnim,
        exit_class_and_navigate,
        render_class_progress,
    };
}
