interface wordsDict {
    [key: string]: Set<string>;
}

const glasses = new Set<string>(['משקפיים', 'כפיים', 'משקפי', 'משתפר', 'משקפת']);

const fork = new Set<string>(['מזלג', 'בלאק', 'לייק', 'מתלה', 'מדלן', 'מדרג', 'מייזנר', 'מזג', 'מזל', 'מה זה']);

const teacher = new Set<string>(['מורה', 'נורא', 'לא נורא', 'מראה', 'דורה', 'אורה', 'תאורה']);

const car = new Set<string>(['מכונית', 'חולית', 'אוטו', 'רונית']);

const music = new Set<string>(['מוסיקה', 'מוזיקה']);

const camera = new Set<string>(['מצלמה']);

const table = new Set<string>(['שולחן']);

const bell = new Set<string>(['פעמון']);

const computer = new Set<string>(['מחשב', 'נחשב']);

const icePlace = new Set<string>([
    'גלידריה',
    'גלידרייה',
    'גלידה',
    'חנות גלידה',
    'לידה',
    'גליקריה',
    'אידיליה',
    'חנות גלידות',
    'לידה',
    'חנות גלי',
]);

const inAStore = new Set<string>([
    'בחנות',
    'בשום חנות',
    'חנות',
    'סובלנות',
    'סבלנות',
    'סבלנות לא',
    'שום חנות',
    'שולחנות',
]);

const hello = new Set<string>(['שלום', 'כלום', 'שלום שלום']);

const sonic = new Set<string>(['סוניק', 'יוני', "ג'וני", 'סוני']);

const book = new Set<string>(['ספר', 'סטפן', 'פפר']);

const cup = new Set<string>(['ספל', 'סטפן']);

const boat = new Set<string>(['סירה', 'שירה', 'סימון', 'פירה', 'דירה', 'טירה', 'פינה']);

const sugar = new Set<string>(['סוכריה', 'סוכרייה']);

const bench = new Set<string>(['ספסל']);

const lemon = new Set<string>(['לימון', 'מימון', 'ליאון', 'גלידת לימון', 'רימון', 'אימון']);

const chocolate = new Set<string>(['שוקולד']);

const vanilla = new Set<string>(['וניל', 'ונילה', 'תנין', 'בניל', 'בני', 'בפנים', 'ואני']);

const congrats = new Set<string>(['מזלטוב', 'מזל טוב', 'מזל']);

const health = new Set<string>(['בריאות', 'ריהוט']);

const love = new Set<string>(['אהבה', 'ארבע', 'האהבה', 'אבא']);

const friends = new Set<string>(['חברים', 'חבר', 'קריבים']);

const happiness = new Set<string>(['אושר']);

const cat = new Set<string>(['חתול', 'חתלתול', 'חתולה', 'חתולי', 'חתולים', 'חתולות', 'חתולונים', 'חתולונות']);

const fox = new Set<string>(['שועל', 'על', 'שואה']);

const elephant = new Set<string>(['פיל', 'פעיל', 'פיל פיל']);

const tiger = new Set<string>(['נמר', 'נמיר', 'ממיר', 'עומר', 'ניימאר', 'נמיר']);

const girl = new Set<string>(['ילדה', 'יאנגה']);

const nail = new Set<string>(['מסמר', 'מסנן', 'סמר', 'אסמר']);

const fence = new Set<string>(['גדר', 'גדי']);

const final_m = new Set<string>(['מם סופית', 'ממ סופית', 'ממ', 'מטוסים', 'מצופים', 'מה הם סופית']);

const final_n = new Set<string>(['נון סופית', 'נונ סופית', 'נונ', 'נון']);

const final_cha = new Set<string>(['צדיק סופית', 'צ סופית', 'צדיק', 'צ', 'סטטיק', 'צדי סופית', 'כדי סופית']);

const final_f = new Set<string>([
    'פ סופית',
    'פי סופית',
    'פה סופית',
    'פיי סופית',
    'פי',
    'פה',
    'פיי',
    'ביטוסי',
    'היי סופי',
    'היי סופית',
    'פייסבוק',
    'חשופית',
]); //כפכפים

const final_kaf = new Set<string>(['כ סופית', 'כף סופית', 'כף', 'חצופית', 'האסופית']);

const fish = new Set<string>(['דג', 'באג']);

const ladder = new Set<string>(['סולם', 'שולמן', 'אולם', 'סולמה', 'שולם', 'שולה', 'שוהם', 'סוללה', 'כולם']);

const monkey = new Set<string>(['קוף', 'קור']);

const plant = new Set<string>(['עציץ', 'השיש', 'ציץ', 'עתיק', 'עסיס']);

const king = new Set<string>(['מלך']);

const strawberries = new Set<string>(['תותים', 'תות']);

const grapes = new Set<string>(['ענבים', 'ענב', 'ארנבים']);

const swing = new Set<string>(['נדנדה']);

const sandbox = new Set<string>(['ארגז חול', 'חול', 'ארגז החול']);

const slide = new Set<string>(['מגלשה', 'מיגלשה']);

const carousel = new Set<string>(['קרוסלה', 'קרוסל', 'רוצה לה', 'עושה לה', 'קופלה', 'סלע']);

const lion = new Set<string>(['אריה', 'אריאל']);

const pear = new Set<string>(['אגס', 'אגד', 'הדס', 'אגם']);

const camel = new Set<string>(['גמל', 'גמל נט', 'כמה']);

const milk = new Set<string>(['חלב', 'אלמ', 'עליו']);

const violin = new Set<string>(['כינור', 'דינור']);

const dog = new Set<string>(['כלב', 'לב']);

const hotel = new Set<string>(['מלון', 'מילון']);

const snake = new Set<string>(['נחש']);

const orange = new Set<string>(['תפוז']);

const red = new Set<string>(['אדום']);

const banana = new Set<string>(['בננה', 'בננות', 'רעננה', 'הבננות']);

const pig = new Set<string>(['חזיר', 'חזי', 'גיר', 'ניר', 'חזיר יבלות', 'חזיר ורוד', 'חזיר בר', 'חזרזיר']);

const flute = new Set<string>(['חליל', 'חלי']);

const guitar = new Set<string>(['גיטרה', 'גדרה']);

const donkey = new Set<string>(['חמור']);

const green = new Set<string>(['ירוק']);

const blue = new Set<string>(['כחול']);

const shark = new Set<string>(['כריש']);

const white = new Set<string>(['לבן']);

const lizard = new Set<string>(['לטאה', 'לטה']);

const motorcycle = new Set<string>(['אופנוע', 'נועה', 'מופע']);

const owl = new Set<string>(['ינשוף']);

const rabbit = new Set<string>(['ארנבת', 'ארנב', 'ערס', 'אבא']);

const snail = new Set<string>(['חילזון', 'חלזון', 'לזון', 'מיה זון']);

const spider = new Set<string>(['עכביש']);

const pepper = new Set<string>(['פלפל', 'פילפל', 'פילבר']);

const lightblue = new Set<string>(['תכלת', 'חלק', 'חלד']);

const apple = new Set<string>([
    'תפוח',
    'תפוחים',
    'פוך',
    'פוח',
    'רוח',
    'לתפוחים',
    'אל התפוחים',
    'הפוך',
    'תפוחים',
    'התפוחים',
]);

const eight_apples = new Set<string>(['שמונה תפוחים']);

const butterfly = new Set<string>(['פרפר', 'פרפרים']);

const airplane = new Set<string>(['מטוס', 'מטוסים', 'אווירון', 'אווירונים', 'abbey road']);

const ball = new Set<string>(['כדור', 'כדורים', 'תורים', 'דור', 'כדורי']);

const cow = new Set<string>(['פרה', 'כפרע', 'הרע', 'כפרה']);

const chicken = new Set<string>(['תרנגול', 'תרנגולת', 'גולט']);

const parrot = new Set<string>(['תוכי']);

const dolphin = new Set<string>(['דולפין', 'סין']);

const horse = new Set<string>(['סוס']);

const canguro = new Set<string>(['קנגורו', 'קנגרו', 'גן גורו']);

const giraph = new Set<string>([
    'גיראפה',
    'גיראף',
    'ג׳ירף',
    'ג׳ירפה',
    'גירפה',
    'מירה פה',
    'נראה פה',
    'גראפה',
    'שיר יפה',
    'מירב',
    'דירה',
]);

const yellow_flowers = new Set<string>(['צהוב', 'צהובים', 'פרחים צהובים', 'הצהובים', 'יותר צהובים', 'אהובים']);

const white_flowers = new Set<string>(['לבן', 'לבנים', 'פרחים לבנים', 'הלבנים', 'לבנה', 'לבה']);

const flowers_general = new Set<string>(['סרוגים', 'דובים']);

const rudi = new Set<string>([
    'לרודי',
    'רודי',
    'לארנב',
    'אודי',
    'הודיס',
    'רותי',
    'לרודיל',
    'רובי',
    'רו',
    'רוני',
    'פודי',
    'לרוני',
    'לרוזי',
    'לאודי',
    'ארנב',
    'דודי',
    'מודי',
]);

const triangle = new Set<string>(['משולש', 'משולשים', 'לשים', 'שולה', 'שילת', 'משולם']);

const circle = new Set<string>([
    'עיגול',
    'תרגול',
    'גול',
    'איפור',
    'ריקול',
    'ניקול',
    'ריגול',
    'מיקוד',
    'ריקוד',
    'טיפול',
]);

const rect = new Set<string>(['ריבוע', 'מרובע', 'מרובעים', 'פיגוע', 'קיבוע', 'rebar', 'גואה', 'בועה']);

const red_tree = new Set<string>(['אדום', 'האדום', 'גזע אדום', 'עץ אדום', 'הגזע האדום', 'העץ האדום']);

const yellow_flower = new Set<string>(['צהוב', 'הצהוב', 'הפרח הצהוב', 'פרח צהוב', 'הפרחים הצהובים', 'טובי', 'סירובים']);

const blue_tree = new Set<string>(['כחול', 'הכחול', 'עץ כחול', 'העץ הכחול', 'גזע כחול', 'הגזע הכחול']);

const even = new Set<string>(['זוגי', 'סוגי', 'דוגי', 'סוקי', 'גוגי', 'לוגי', 'יוגי', 'דוגית']);

const eyes = new Set<string>(['עניים', 'עיניים', 'זוג עיניים']);

const face_pairs = new Set<string>(['לחיים', 'גבות', 'נחיריים', 'אוזניים', 'שפתיים']);

const allWords: wordsDict = {
    משולש: triangle,
    עיגול: circle,
    ריבוע: rect,
    רודי: rudi,
    תפוח: apple,
    שמונה_תפוחים: eight_apples,
    פרפר: butterfly,
    מטוס: airplane,
    כדור: ball,
    פרה: cow,
    תוכי: parrot,
    דולפין: dolphin,
    תרנגול: chicken,
    סוס: horse,
    גיראפה: giraph,
    קנגורו: canguro,
    משקפיים: glasses,
    מזלג: fork,
    מורה: teacher,
    מכונית: car,
    מוסיקה: music,
    מצלמה: camera,
    שולחן: table,
    פעמון: bell,
    מחשב: computer,
    גלידריה: icePlace,
    בחנות: inAStore,
    שלום: hello,
    סוניק: sonic,
    ספר: book,
    ספל: cup,
    סירה: boat,
    סוכריה: sugar,
    ספסל: bench,
    לימון: lemon,
    שוקולד: chocolate,
    וניל: vanilla,
    מזלטוב: congrats,
    בריאות: health,
    אהבה: love,
    חברים: friends,
    אושר: happiness,
    חתול: cat,
    שועל: fox,
    פיל: elephant,
    נמר: tiger,
    ילדה: girl,
    מסמר: nail,
    גדר: fence,
    דג: fish,
    סולם: ladder,
    קוף: monkey,
    עציץ: plant,
    מלך: king,
    final_m: final_m,
    final_n: final_n,
    final_cha: final_cha,
    final_f: final_f,
    final_kaf: final_kaf,
    תותים: strawberries,
    ענבים: grapes,
    נדנדה: swing,
    ארגז_חול: sandbox,
    מיגלשה: slide,
    קרוסלה: carousel,
    אריה: lion,
    אגס: pear,
    גמל: camel,
    חלב: milk,
    כינור: violin,
    כלב: dog,
    מלון: hotel,
    נחש: snake,
    תפוז: orange,
    אדום: red,
    בננה: banana,
    גיטרה: guitar,
    חזיר: pig,
    חליל: flute,
    חמור: donkey,
    ירוק: green,
    כחול: blue,
    כריש: shark,
    לבן: white,
    לטאה: lizard,
    אופנוע: motorcycle,
    ארנב: rabbit,
    חילזון: snail,
    ינשוף: owl,
    עכביש: spider,
    פילפל: pepper,
    תכלת: lightblue,
    פרחים_צהוב: yellow_flowers,
    פרח_לבן: white_flowers,
    פרחים_כללי: flowers_general,
    עץ_אדום: red_tree,
    עץ_כחול: blue_tree,
    פרח_צהוב: yellow_flower,
    זוגי: even,
    עיניים: eyes,
    זוגות_פנים: face_pairs,
};

export function useWord() {
    const check_word = (words: string[], match: string): boolean => {
        if (!/[\u0590-\u05FF]/.test(match)) match = match.replace(/[^\x00-\x7F]/g, '');

        console.log(match, words);

        if (!words.every((word) => word in allWords)) return false;
        return words.some((word) => allWords[word].has(match));
    };

    return { check_word };
}
