import './assets/css/global.css';
import './assets/css/fonts.css';
import './assets/css/colors.css';

import React, { useContext } from 'react';
import Header from './shared/header/Header';
import SecRecom from './components/sec-recom/SecRecom';
import SecSell from './components/sec-best/SecSell';
import SecNightHeb from './components/sec-night/SecNightHeb';
import SecFAQ from './components/sec-faq/SecFAQ';
import SecAbout from './components/sec-about/SecAbout';
import SecSlider from './components/sec-slider/SecSlider';
import Footer from './shared/footer/Footer';
import { Helmet } from 'react-helmet';
import LoginModal from 'src/common/login/LoginModal';
import PaymentModal from 'src/common/payment/PaymentModal';
import { AppContext } from 'src/AppContext';
import { useNav } from 'src/common/useNav';
import { is_reffered_by_soical } from 'src/common/common';
import SecCourse from './components/sec-best/SecCourse';
import Logo from 'src/landing/assets/images/logo_school.svg';
import SecNightMath from './components/sec-night/SecNightMath';
import SecMeetSchool from './components/sec-meet/SecMeetSchool';
import SectionTwoSchool from './components/sec-hero/SectionTwoSchool';
import SecRespSchool from './components/sec-hero/SecRespSchool';

function LandingPageSchool(): JSX.Element {
    const { state } = useContext(AppContext);
    const { navigateToHome } = useNav();

    React.useEffect(() => {
        const token = localStorage.getItem('ib_token');
        if (token && state.subscribed && !is_reffered_by_soical()) navigateToHome();
    }, []);

    const primBtnText = 'אני רוצה את דמיון בקופסא';

    return (
        <>
            <Helmet>
                <link rel="canonical" href={'https://imagineinabox.com'} />
            </Helmet>
            <Header logo={Logo} />
            <main>
                <SecMeetSchool />
                <SecRecom primBtnText={primBtnText} />
                <SectionTwoSchool primBtnText={primBtnText} />
                <SecSlider />
                <SecAbout />
                <SecNightMath sentence_one="בילי החתולה יוצאת למסע קסום." />
                <SecNightHeb primBtnText={primBtnText} />
                <SecCourse />
                <SecSell />
                <SecRespSchool />
                <SecFAQ />
            </main>
            <Footer />
            <PaymentModal />
            <LoginModal />
        </>
    );
}

export default LandingPageSchool;
