import { playSound } from 'src/common/common';
import errSound from '../../assets/sounds/error.mp3';
import sccSound from '../../assets/sounds/success1.mp3';
import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { IMic } from './Mic';
import transcribe, { TranscribeType } from '../../common/api/mic.api';
import { IMicTask } from '../../wrapper';
import { useAB } from './matchers/useAB';
import { useRhyme } from './matchers/useRhyme';
import { useWord } from './matchers/useWord';
import { useABFix } from './matchers/useABFix';
import { logErrorinServer } from 'src/common/api/server.api';
import { useAudioRecorderFix } from './useAudioRecoderFix';
import { useServer } from 'src/common/api/useServer';

const mic_duration = 2.5;
const max_trials = 2;

const getExpiryTimestamp = () => {
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + mic_duration);
    return expiryTimestamp;
};

export function useMic(props: IMic) {
    const { check_rhyme } = useRhyme();
    const { check_ab } = useAB();
    const { check_word } = useWord();
    const { check_ab_fix } = useABFix();
    const { handle_no_token_err } = useServer();

    const [audioErr, setAudioErr] = React.useState<string>();
    const [reportErr, setReportErr] = React.useState<string>();
    const [perm_granted, setPermGranted] = React.useState<string>('prompt');

    const onAudioReady = (blob: Blob | null) => {
        if (!blob) {
            const token = localStorage.getItem('ib_token') ?? 'token removed';
            logErrorinServer(`mic_error_no_blob`, token);
            setReportErr('לא הצלחנו לקלוט את מה שאמרתם, נסו שנית');
            return;
        }

        const type = get_transcript_type(props.task!);
        const match =
            props.task?.rhyme || props.task?.ab_match || props.task?.word_match || props.task?.word_fix_match?.ab;

        if (!props.task?.force) {
            const exceptions = ['כ', 'ק', 'ט'];
            if (type === 'eng' && exceptions.includes(match as string)) return onApiResult(match as string);
        }
        transcribe(blob, type, match?.toString(), onApiResult, handle_no_token_err);
    };

    useEffect(() => {
        if (audioErr) {
            const token = localStorage.getItem('ib_token') ?? 'token removed';
            if (audioErr === 'Permission denied') {
                setReportErr('היי! עליכם לאשר בהגדרות הדפדפן את השימוש במיקרופון');
                logErrorinServer('mic_error_permission_denied', token);
            } else if (audioErr === 'err_check_mic_perm') {
                setReportErr('היי! נתקלנו בבעיה בבדיקת הרשאות המיקרופון');
                logErrorinServer('mic_error_check_mic_permission', token);
            } else {
                console.error('Error:', audioErr);
                logErrorinServer(`mic_error_${audioErr}`, token);
                setReportErr('היי! המיקרופון לא נתמך בדפדפן שבו אתם גולשים. נסו דפדפן או מכשיר אחר');
            }
        }
    }, [audioErr]);

    React.useEffect(() => {
        const checkMicrophonePermission = async () => {
            try {
                const permissions = await navigator.permissions.query({ name: 'microphone' as PermissionName });
                setPermGranted(permissions.state);
                if (permissions.state === 'granted') {
                    console.log('Microphone permission granted');
                } else if (permissions.state === 'denied') {
                    setAudioErr('Permission denied');
                    console.error('Microphone permission denied');
                } else {
                    console.log('Microphone permission prompt required');
                }

                permissions.onchange = () => {
                    setPermGranted(permissions.state);
                    if (permissions.state === 'granted') {
                        console.log('Microphone permission suddenly granted');
                        setAudioErr(undefined);
                        setReportErr(undefined);
                    } else if (permissions.state === 'denied') {
                        setAudioErr('Permission denied');
                    }
                };
            } catch (err) {
                setAudioErr('err_check_mic_perm');
                console.error('Error checking microphone permission:', err);
            }
        };

        checkMicrophonePermission();
    }, []);

    const [record, setRecord] = React.useState(false);
    const [errors, setErrors] = React.useState(0);

    const { startRecording, stopRecording } = useAudioRecorderFix({
        onAudioReady,
        setError: setAudioErr,
    });

    const expiryTimestamp = getExpiryTimestamp();

    const { start, restart } = useTimer({
        expiryTimestamp,
        onExpire: () => stopRecord(),
        autoStart: false,
    });

    const onApiResult = (transcribedText: string) => {
        const success = check_task(transcribedText, props.task!);
        if (success) {
            if (props.successSound) playSound(sccSound, 0.2);
            setErrors(0);
            props.mic_over_cb!();
            return;
        }
        // console.log('errors', errors);

        if (errors < max_trials) {
            setErrors(errors + 1);
            playSound(errSound, 0.5);
            return;
        } else if (errors === max_trials) {
            setErrors(0);
            props.mic_over_cb!();
        }
    };

    const startRecord = () => {
        if (perm_granted === 'denied') return;
        setAudioErr(undefined);
        setReportErr(undefined);

        start();
        setRecord(true);
        startRecording();
    };

    const stopRecord = () => {
        setRecord(false);
        stopRecording();
        const expiryTimestamp = getExpiryTimestamp();
        restart(expiryTimestamp, false);
    };

    const check_task = (transcribedText: string, task: IMicTask): boolean => {
        if (task.word_match) return check_word(task.word_match, transcribedText);
        else if (task.rhyme) return check_rhyme(task.rhyme, transcribedText);
        else if (task.ab_match) return check_ab(task.ab_match, transcribedText);
        else if (task.word_fix_match) return check_ab_fix(task.word_fix_match, transcribedText);
        return true;
    };

    const get_transcript_type = (task: IMicTask): TranscribeType => {
        if (task.word_match) return 'heb';
        if (task.rhyme) return 'heb';
        if (task.ab_match) return 'eng';
        if (task.word_fix_match) return 'heb';
        return 'eng';
    };

    return { record, startRecord, reportErr };
}
